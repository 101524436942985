<template>
  <div>我的佣金</div>
</template>

<script>
export default {
  name: 'MyMoney',
}
</script>

<style scoped>

</style>
